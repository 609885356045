import { template as template_5e78c57c75744e96b872e2ab497802df } from "@ember/template-compiler";
const FKControlMenuContainer = template_5e78c57c75744e96b872e2ab497802df(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

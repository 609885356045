import { template as template_01d9cdab9e2e49fa91e22c1c532849fc } from "@ember/template-compiler";
const FKLabel = template_01d9cdab9e2e49fa91e22c1c532849fc(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

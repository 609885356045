import { template as template_40d7b25aa72c4fe4b314b164a69a8285 } from "@ember/template-compiler";
const WelcomeHeader = template_40d7b25aa72c4fe4b314b164a69a8285(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
